<template>
    <div class="resetPasswordContainer">
        <b-form @submit="setNewPassword" class="bg-white text-left resetForm">

            <h3>{{ $t(translationPath + 'reset_password')}}</h3>
            <div v-if="isTokenValid && isLoaded && !isAlreadyReset">
                <b-form-group
                    class="passwordInputGroup mb-4"
                    :invalid-feedback="invalidPasswordFeedback"
                    :state="!$v.newPassword.$error">
                    <template slot="label" tabindex="-1">
                        {{ $t(translationPath + 'label.new_password') }}
                    </template>
                    <b-form-input
                        class="passwordInput"
                        type="password"
                        v-model="newPassword"
                        :placeholder="$t(translationPath + 'label.new_password_placeholder')"
                        :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
                        @input="onPasswordInput">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    class="passwordInputGroup mb-4"
                    :invalid-feedback="invalidConfirmPasswordFeedback"
                    :state="!$v.confirmNewPassword.$error">
                    <template slot="label" tabindex="-1">
                        {{ $t(translationPath + 'label.confirm_new_password') }}
                    </template>
                    <b-form-input
                        class="passwordInput"
                        type="password"
                        v-model="confirmNewPassword"
                        :placeholder="$t(translationPath + 'label.confirm_new_password_placeholder')"
                        :state="$v.confirmNewPassword.$dirty ? !$v.confirmNewPassword.$error : null"
                        @input="onConfirmPasswordInput">
                    </b-form-input>
                </b-form-group>

                <b-button type="submit" variant="primary" class="w-100 text-uppercase">{{ $t(translationPath + 'label.save_new_password') }}</b-button>

            </div>
            <div v-else-if="!isTokenValid && isLoaded">

              <b-alert variant="danger" :show="isLoaded">
                <p><strong>{{ $t( 'login.reset.errors.token_incorrect_error_title')}}</strong><br/>
                {{ $t( 'login.reset.errors.token_incorrect_error_msg') }}
                </p>
                <p>
                  <b-link href="javascript:void(0);" @click="gotoResetPassword">{{ $t( 'login.reset.errors.goto_reset_link') }} →</b-link>
                </p>
              </b-alert>

            </div>
            <div v-else-if="!isLoaded">
              <loader loading-text="" class="mt-5" />
            </div>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import Loader from '@/components/common/Loader'

export default {
  name: 'ResetPassword',
  components: { Loader },
  data () {
    return {
      isTokenValid: false,
      isLoaded: false,
      newPassword: null,
      confirmNewPassword: null,
      sameAsPassword: false,
      translationPath: 'login.reset.',
      error: null,
      isAlreadyReset: false
    }
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(8)
    },
    confirmNewPassword: {
      sameAsPassword: sameAs('newPassword')
    },
    form: ['newPassword', 'confirmNewPassword']
  },
  computed: {
    invalidPasswordFeedback () {
      if (this.$v.newPassword.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_password') }
      if (this.$v.newPassword.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.min_length') }
      return ''
    },
    invalidConfirmPasswordFeedback () {
      if (this.$v.confirmNewPassword.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_confirm_password') }
      if (this.$v.confirmNewPassword.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.min_length') }
      if (this.$v.confirmNewPassword.sameAsPassword === false) { return this.$i18n.t(this.translationPath + 'errors.no_match') }
      return ''
    },
    validatedState () {
      return this.$v.confirmNewPassword.$error === false && this.$v.newPassword.$error === false
    }
  },
  methods: {
    gotoResetPassword () {
      this.$emit('forgot-password')
    },
    onPasswordInput () {
      this.$v.newPassword.$touch()
    },
    onConfirmPasswordInput () {
      this.$v.confirmNewPassword.$touch()
    },
    resetForm () {
      this.validated = false
      this.submitted = false
      this.newPassword = ''
      this.confirmNewPassword = ''
    },
    async setNewPassword (event) {
      event.preventDefault()
      this.$v.form.$touch()

      this.validated = false
      if (this.validatedState) {
        this.isLoaded = false
        const postData = {
          password: this.newPassword,
          token: this.$route.query.token
        }

        axios.put(`${process.env.VUE_APP_ROOT_API}/user/password/update/${this.$route.query.token}`, postData)
          .then(response => {
            this.resetForm()
            this.isAlreadyReset = true

            this.$emit('success-reset', {
              email: response.data.data.email
            })
          })
          .catch((err) => {
            console.error(err)
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.password_update_failed_msg'), {
              title: this.$t(this.translationPath + 'toast.password_update_failed_title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .then(() => {
            this.isLoaded = true
          })
      }
      return true
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_ROOT_API}/user/password/token/check/${this.$route.query.token}`)
      .then(response => {
        this.isTokenValid = response.data.data.success
      })
      .catch((err) => {
        console.log(err)
      })
      .then(() => {
        this.isLoaded = true
      })
  },
  watch: {
    isTokenValid: function (val) {
      this.isTokenValid = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../style.scss";
.resetForm{
    border: 3px solid #ececec;
    border-radius: 30px;
}
</style>
