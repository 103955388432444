<template>
  <div>
    <div id="login-wrapper">
      <b-container class="login page-wrapper bg-light pb-5">
        <b-row class="main-content-wrapper pt-0" no-gutters>
          <b-col cols="12" md="5" class="pl-5 pr-5 pr-md-0 mr-5">
            <ul
              class="
                d-md-flex
                flex-md-column
                justify-content-md-end
                align-items-md-end
                mb-md-5
                text-uppercase
              "
            >
              <li @click="$router.push({ name: 'login' })">
                {{ $t("login.label.login_with_email") }}
              </li>
              <li @click="$router.push({ name: 'forgotPassword' })">
                {{ $t("login.label.forgot_password") }}
              </li>
              <!--<li @click="$router.push({ name: 'loginWithBank' })">
                {{ $t("login.label.login_width_mobile_bankid") }}
              </li>-->
              <!--<li @click="resetStateTo('registerAsNewUser')" :class="{'elem-active': registerAsNewUser}">{{ $t('login.label.signup_for_spirecta') }}</li>-->
            </ul>
          </b-col>

          <b-col cols="12" md="6" class="pl-5 pr-5 pr-md-0">
            <reset-password
              @success-reset="handleSuccessReset"
              @forgot-password="$router.push({ name: 'forgotPassword' })"
            ></reset-password>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <reset-password-success-modal ref="ResetPasswordSuccessModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ResetPassword from '../components/ResetPassword'
import ResetPasswordSuccessModal from '../components/ResetPasswordSuccessModal'
import { SET_SETTINGS_ACTIVE_LANG_ID } from '@/store/actions/settings'

// import RegisterNewUser from './components/RegisterNewUser'

export default {
  name: 'login',
  components: { ResetPassword, ResetPasswordSuccessModal },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['isAuthenticated'])
  },
  created () {
    const queryParams = this.$route.query
    const validLangParams = ['en', 'sv', 'da']
    if (Object.keys(queryParams).length > 0) {
      const langParam =
        Object.prototype.hasOwnProperty.call(queryParams, 'lang') &&
        validLangParams.indexOf(queryParams.lang) > -1
          ? queryParams.lang
          : null
      if (langParam) {
        this.$store.dispatch(SET_SETTINGS_ACTIVE_LANG_ID, langParam)
      }
    }
  },
  methods: {
    handleSuccessReset (value) {
      this.$refs.ResetPasswordSuccessModal.show()
      /* setTimeout(() => {
        this.router.push({
          name: 'login'
        })
      }, 1000) */
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
</style>
