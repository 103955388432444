<template>
  <b-modal
    id="reset-password-success-modal"
    centered
    ref="ResetPasswordSuccessModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t('login.reset.success_reset_modal.title')}}
    </template>
    <h6 class="mb-0 font-weight-lighter">{{ $t('login.reset.success_reset_modal.text') }}</h6>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" @click="onGoToLogin">{{ $t('login.label.login') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ResetPasswordSuccessModal',
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
      this.$router.push({ name: 'login' })
    },
    show () {
      this.$refs.ResetPasswordSuccessModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.ResetPasswordSuccessModal.hide()
      this.$emit('hide')
    },
    onGoToLogin () {
      this.$refs.ResetPasswordSuccessModal.hide()
      this.$emit('goto-login')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
